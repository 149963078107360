<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    @onHiddenSidebar="handleCancel"
  >
    <RequestDetailForm
      v-if="(request !== undefined)"
      :request="request"
      :invitationStatus="invitationStatus"
      :invitationSender="invitationSender"
      :invitationId="invitationId"
      @handleChangeRequest="handleChangeRequest"
      @handleAcceptInvitation="handleAcceptInvitation"
      @handleRejectInvitation="handleRejectInvitation"
      @handleCancelInvitation="handleCancelInvitation"
    />
  </SideBar>
</template>

<script>
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
import RequestDetailForm from './RequestDetailForm.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    request: {
      type: Object,
      required: true,
    },
    invitationStatus: String,
    invitationSender: Boolean,
    invitationId: String,
  },
  data() {
    return {
      requestData: this.request,
    };
  },
  watch: {
    request(newVal) { this.requestData = newVal; },
  },
  methods: {
    handleChangeRequest(uuid, status) {
      this.$emit('handleChangeRequest', uuid, status);
    },
    handleAcceptInvitation(id) {
      this.$emit('handleAcceptInvitation', id);
    },
    handleRejectInvitation(id) {
      this.$emit('handleRejectInvitation', id);
    },
    handleCancelInvitation(id) {
      this.$emit('handleCancelInvitation', id);
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    SideBar,
    RequestDetailForm,
  },
};
</script>

<style lang="sass">
  @import './SideForm.sass'
</style>
